import 'core-js/stable'
import 'regenerator-runtime/runtime'

import initGlobal, { initPageFooter, initPageHeader } from '@bikemap/js/integrations/global'
import replaceCounters from '@bikemap/js/integrations/counters'

let carouselAnimating, countryCarousel
const itemWidth = 226 // width will always be 200px, and margin always 26px, so we can hardcode this
const carouselWidth = 1582 // 7 items

initGlobal()
initPageHeader()
initPageFooter()
replaceCounters()
init()

function checkCarouselWidth() {
    if (carouselWidth > document.body.clientWidth) {
        if (!carouselAnimating) {
            carouselAnimating = true
            countryCarousel.classList.add('animated')
            window.requestAnimationFrame(updateCarousel)
        }
    } else {
        carouselAnimating = false
        countryCarousel.classList.remove('animated')
    }
}

function updateCarousel() {
    if (carouselAnimating) {
        const items = countryCarousel.getElementsByClassName('carousel-item')

        for (const item of items) {
            // check if an item is off-screen, if yes then move it to the right
            if (item.getBoundingClientRect().x + itemWidth < 0) {
                const copy = item.cloneNode(true)
                copy.style.left = (parseInt(items[items.length - 1].style.left, 10) + itemWidth) + 'px'
                item.remove()
                countryCarousel.appendChild(copy)
            }

            // finally, animate the items
            item.style.left = (parseInt(item.style.left, 10) - 1) + 'px'
        }

        window.requestAnimationFrame(updateCarousel)
    }
}

function init() {
    countryCarousel = document.getElementById('country-carousel')

    // clone elements for animation
    const clones = document.createDocumentFragment()

    for (const item of countryCarousel.getElementsByClassName('carousel-item')) {
        item.style.left = '0px'

        const clone = item.cloneNode(true)
        clone.classList.add('cloned')
        clones.appendChild(clone)
    }

    countryCarousel.appendChild(clones)

    // set initial position for elements
    let width = 0
    for (const item of countryCarousel.getElementsByClassName('carousel-item')) {
        item.style.left = width + 'px'
        width += itemWidth
    }

    window.addEventListener('resize', checkCarouselWidth)
    checkCarouselWidth()
}
